import { BlueGrouping } from "components/color-groupings/blue-grouping/BlueGrouping.component";
import { WhiteGrouping } from "components/color-groupings/white-grouping/WhiteGrouping.component";
import {
  GET_MORE_INFO,
  QUESTION_MINIMIZE_BREAKPOINT,
} from "components/constants";
import { CoreValues } from "components/page-specific-components/schools/components/athletics/core-values/CoreValues.component";
import { Carousel } from "components/page-specific-components/schools/components/carousel/Carousel.component";
import "components/page-specific-components/schools/components/carousel/Carousel.styles.scss";
import { ImageHeading } from "components/page-specific-components/schools/components/image-heading/ImageHeading.component";
import { SloganZigZag } from "components/page-specific-components/schools/components/slogan-zig-zag/SloganZigZag.component";
import { StaffCards } from "components/page-specific-components/schools/components/staff-cards/StaffCards.component";
import { staffCards as employeeCards } from "components/page-specific-components/schools/components/staff-cards/StaffCards.config";
import { sortStaffCards } from "components/page-specific-components/schools/components/staff-cards/StaffCards.helpers";
import { StackedCallToAction } from "components/stacked-call-to-action/StackedCallToAction.component";
import { SVGIcons } from "components/svg-wrapper/SvgWrapper.enum";
import { StaticImage } from "gatsby-plugin-image";
import { useWindowWidth } from "hooks/windowWidth.hook";
import React, { useState } from "react";
import linksService from "services/links.service";
import { middleSchoolSlogans, seasons } from "./MiddleSchool.config";

export const MiddleSchool = () => {
  const { middleSchoolCards, administrationCards, staffCards } =
    sortStaffCards(employeeCards);
  const [seasonIndex, setSeasonIndex] = useState(0);
  const windowWidth = useWindowWidth();

  const isMobile = windowWidth <= QUESTION_MINIMIZE_BREAKPOINT;

  return (
    <main>
      <WhiteGrouping>
        <section
          className="fix-header-overlap"
          style={{ paddingBottom: "7rem", overflowX: "hidden" }}
        >
          <ImageHeading heading="Middle School">
            <StaticImage
              src="../../images/jpg/middle_school.jpg"
              alt="two kids smiling and looking at each other"
              placeholder="blurred"
              layout="constrained"
              style={{
                marginTop: "-2rem",
              }}
            />
          </ImageHeading>
          <SloganZigZag slogans={middleSchoolSlogans} />
          <div className="layout">
            <StackedCallToAction
              headerText={
                isMobile
                  ? GET_MORE_INFO.HEADER.MOBILE
                  : GET_MORE_INFO.HEADER.DESKTOP
              }
              paragraphText={
                isMobile
                  ? GET_MORE_INFO.PARAGRAPH.MOBILE
                  : GET_MORE_INFO.PARAGRAPH.DESKTOP
              }
              buttonObj={{
                label: GET_MORE_INFO.BUTTON_LABEL.DESKTOP,
                icon: SVGIcons.Document,
                link: linksService.inquiryForm(),
                newTab: true,
              }}
            />
          </div>
        </section>
      </WhiteGrouping>
      <BlueGrouping>
        <section className="layout athletics-section">
          <div className="top-row">
            <CoreValues />
            <div className="carousel-wrapper">
              <Carousel
                seasons={seasons}
                seasonIndex={seasonIndex}
                setSeasonIndex={setSeasonIndex}
              />
            </div>
          </div>
          <div className="athletics-paragraphs">
            <h4>About Our Middle School Athletics</h4>
            <p>
              Twice a week during the school day, middle school students are
              given the choice to take a general PE class or participate in
              sports practice.
            </p>
            <p>
              For students participating in a sport, they will also have around
              8-10 games after school.
            </p>
          </div>
        </section>
      </BlueGrouping>
      <WhiteGrouping>
        <section className="layout">
          <StaffCards heading="Faculty" cards={middleSchoolCards} />
          <StaffCards heading="Administration" cards={administrationCards} />
          <StaffCards heading="Staff" cards={staffCards} />
        </section>
      </WhiteGrouping>
    </main>
  );
};
