import archery from "images/jpg/archery.jpg";
import biking from "images/jpg/biking.jpg";
import crafting from "images/jpg/crafting.jpg";
import posing from "images/jpg/posing.jpg";
import running from "images/jpg/running.jpg";
import soccer1 from "images/jpg/soccer1.jpg";
import soccer2 from "images/jpg/soccer2.jpg";
import soccer3 from "images/jpg/soccer3.jpg";
import volleyball from "images/jpg/volleyball.jpg";

export const middleSchoolSlogans = [
    {
      heading: "We provide hands on learning experiences",
      points: [
        "Outdoor science (Animal Center, gardening, ecology, and environmental science)",
        "Maker's Space (integrative learning of robotics, other STEM activities, and art)",
        "Enrichment courses (e.g. theater arts, vocal performance, art, writer's workshop, etc.)",
      ],
      image: {
        src: crafting,
        alt: "two students doing arts and crafts",
      },
    },
    {
      heading: "We focus on developing the whole person",
      points: [
        "Experiential & service learning",
        "STEM activities",
        "Athletics",
        "Student leadership opportunities",
        "Interpersonal skill development (e.g. morning time daily dedicated to social emotional learning)",
      ],
      image: {
        src: archery,
        alt: "boy holding bow and arrows in front of archery targets",
      },
    },
    {
      heading: "We build connections for life",
      points: [
        "At PacBay we are committed to providing a place where students are able to create friendships with one another, parents get to know others in the community, and faculty are devoted to preparing students for a successful future.",
      ],
      image: {
        src: biking,
        alt: "students resting on mountain bikes laughing",
      },
    },
  ];

  export const seasons = [
    {
      label: "Fall",
      images: [volleyball, running, posing],
      sports: [
        "Girls Volleyball",
        "Flag Football",
        "Boys and Girls Cross County",
      ],
    },
    {
      label: "Winter",
      images: [soccer3, soccer1, soccer2],
      sports: [
        "Girls Basketball",
        "Boys Basketball",
        "Girls Soccer",
        "Boys Soccer",
      ],
    },
  ];